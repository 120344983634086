import { render, staticRenderFns } from "./CreatePreScolaire.vue?vue&type=template&id=5dd7ccfa&scoped=true"
import script from "./CreatePreScolaire.vue?vue&type=script&lang=js"
export * from "./CreatePreScolaire.vue?vue&type=script&lang=js"
import style0 from "./CreatePreScolaire.vue?vue&type=style&index=0&id=5dd7ccfa&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5dd7ccfa",
  null
  
)

export default component.exports